import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCurrencyCode } from "./core/commonFun";
import { Skeleton } from "@mui/material";
import { handleBrokenImage } from "../lib/utils";

const ProfileCard = (props) => {
  const navigate = useNavigate();
  const { item, filters, loading } = props;
  const onClick = (item) => {
    //window.open(`${window.location.origin}/profession/${item?.RegisterYourselfAs}/${item?.Name}/${item.Id}`)
    navigate(
      `/profession/${item?.RegisterYourselfAs}/${item?.Name}/${item.Id}`
    );
  };
  var ExpertisesArray = item?.Category?.split(",");
  const search = useLocation().search;
  const Profession_url = new URLSearchParams(search).get("Profession");
  const CurrencyCode = getCurrencyCode();

  return (
    <div
      className="mb-res-30 ani-fade-up mx-1 mx-md-0"
      data-ani-delay="0"
      style={{
        border: "1px solid #E2E2E1",
        borderRadius: "15px",
        background: "#fff",
        cursor: "pointer",
      }}
    >
      <div
        onClick={() => onClick(item)}
        className="col-md-12"
        style={{ textDecoration: "none" }}
      >
        <div
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: 300 }}
        >
          {(filters === true && (
            <div
              className="d-flex align-items-center justify-content-center gap-2"
              style={{
                background: "#FAFAFA",
                borderRadius: "15px 15px 0px 0px",
              }}
            >
              <img src="/assets/img/star_icon.png" alt="" />
              <p className="fs-6 font-inter" style={{ color: "#EDA02B" }}>
                Recommended
              </p>
            </div>
          )) || (
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                style={{
                  background: "#FAFAFA",
                  borderRadius: "15px 15px 0px 0px",
                }}
              >
              </div>
            )}

          <div className="d-flex align-items-start py-3 px-3 gap-1">
            <div className="ms-2 mt-1 col-4">
              <img
                src={item?.ProfileImage}
                onError={(e) => {
                  const newLink = handleBrokenImage(item?.ProfileImage);
                  e.target.src = newLink
                }}
                alt=""
                className="rounded-3 col-12 w-100"
                style={{
                  display: "block",
                  overflow: "hidden",
                  aspectRatio: "1/1",
                  objectFit: "cover",
                  backgroundColor: "rgba(0, 0, 0, 0.11)",
                }}
              />
            </div>
            <div className="ms-4 col-7" style={{ color: "#667085" }}>
              {item?.Expert_Banner && <img src={item?.Expert_Banner} alt="" />}
              <h3 className="font-inter font-w600 fs-5 lh-base">
                {item?.Name.substring(0, 17)}
              </h3>
              <p className="font-inter fs-6 lh-base">
                {item?.RegisterYourselfAs}
              </p>
              <p className="font-inter fs-6 lh-base">
                Years of Exp. : {item?.Experience} yrs
              </p>
              {(item?.Qualifications === null && (
                <div className="d-flex align-items-center  ">
                  <p
                    className="font-inter fs-6 lh-base hideScrollbar"
                    style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                  >
                    {" "}
                    &nbsp;
                  </p>
                </div>
              )) || (
                  <div className="d-flex align-items-center  ">
                    <p
                      className="font-inter fs-6 lh-base hideScrollbar"
                      style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
                    >
                      {item?.Qualifications} &nbsp;
                    </p>
                    <span>...</span>
                  </div>
                )}
              <p className="font-inter fs-6 lh-base">
                <img src="/assets/img/flag.png" className="me-2" alt="" />
                {item?.Country}
              </p>
            </div>
          </div>
          <div
            className="d-flex flex-column gap-3 m-0 py-3 px-3"
            style={{
              background: "#EDE1FF",
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              {item?.NextAvailability ? (
                <div className="col-7 col-md-8">
                  <p
                    className="font-inter fs-6"
                    style={{ color: "#73589b", fontWeight: "500" }}
                  >
                    Next Availability
                  </p>
                  <h6 className="font-inter fs-6 text-black">
                    {item?.NextAvailability} (IST)
                  </h6>
                </div>
              ) : (
                <div className="col-7 col-md-8">
                  <p
                    className="font-inter fs-6"
                    style={{ color: "#73589b", fontWeight: "500" }}
                  >
                    No Slots Available
                  </p>
                  <h6 className="font-inter fs-6 text-black">This Week</h6>
                </div>
              )}
              <button
                id="professional_book_now"
                className="col-5 col-md-4 px-2 rounded-2 border-0 font-inter py-2"
                style={{
                  background: "var(--primary-color)",
                  textAlign: "center",
                }}
              >
                <span className="book-now-a-professional text-decoration-none fs-6 text-white">
                  Book Now
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
